/* eslint-disable camelcase */
// regex for utm campaigns
const socialTypeUtmPattern = /social_.+_perf/;
const displayTypUtmPattern = /display_.+_perf/;

// eslint-disable-next-line import/prefer-default-export
export const isVDPExperienceUTM = (query) => {
    const utm = query?.utm_medium || '';
    const isSem = utm === 'sem_listings_perf';
    const isSocial = socialTypeUtmPattern.test(utm);
    const isDisplay = displayTypUtmPattern.test(utm);

    if (isSem || isSocial || isDisplay) { return true; }

    return false;
};

// eslint-disable-next-line import/prefer-default-export
export const isVDPReengagementExperienceUTM = (query) => {
    const utmMedium = query?.utm_medium || '';
    const utmCampaign = query?.utm_campaign || '';
    const isEmailCampaign = utmMedium === 'email_transactional_crm';
    const isSavedSearch = utmCampaign === 'at_na_na_myatc-alert_saved-search_ctr_na_na';
    const isSavedVehicle = utmCampaign === 'at_na_na_myatc-alert_saved-veh_ctr_na_na';

    return isEmailCampaign && (isSavedSearch || isSavedVehicle);
};

// Get pg ids by page
// This should replace dataIsland.BIRF.page.pg
export const pageIdsByPageName = {
    sfp: () => ({ pg: 'fyc_sf' }),
    fordlanding: () => ({ pg: 'fyc_lnd_fba' }),
    bap: () => ({ pg: 'bld_and_pr' }),
    vdp: ({ brand, id, query, listingType = '' }) => {
        let pg = 'fyc_vdp';
        let brandPg;

        if (!id) {
            const { zip } = query;
            const make = query.makeCodeList;
            const model = query.modelCodeList;

            pg = 'fyc_exvdp_nmmz';

            if (zip && make && model) {
                pg = 'fyc_exvdp_mmz';
            } else if (!zip && make && model) {
                pg = 'fyc_exvdp_make_mdl';
            }
        }

        if (brand === 'kbb') {
            if (id) {
                brandPg = listingType.toUpperCase() === 'NEW' ? 'classdetailnew' : 'classdetailused';
            } else {
                brandPg = 'classdetailexpired';
            }
        }

        return { pg, brandPg };
    },
    srp: ({ brand, showNoResults, listingTypes = [], listingType = [] }) => {
        let pg = 'fyc_srl';
        let brandPg;
        if (showNoResults) {
            pg = 'fyc_ncf';
        }

        let condition = listingType.length > 0 ? listingType : listingTypes;

        if (brand === 'kbb') {
            condition = [].concat(condition);
            condition = condition.map((value) => value.toLowerCase());
            const usedListingType = condition.length > 0 && (condition.includes('used') || condition.includes('certified')) && !condition.includes('new');
            brandPg = usedListingType ? 'classlistused' : 'classlistnew';
        }

        return { pg, brandPg };
    },
};
